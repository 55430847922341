
.show-grid {
    padding: 10px;
  }
  
  .show-grid .maind {
    margin-bottom: 6px;
    display: flex;
    text-align: left;
  }
  
  .show-grid  {
      padding-top: 10px;
      padding-bottom: 24px;
      background-color: #222;
      border: 1px solid rgba(86,61,124,.2);
  }
  .w-100{
    width:100%;
    margin: 9px;
    border-bottom: 1px solid #252323;
    padding-bottom: 5px;
    font-size: 18px;
    color: #fff;
  }
  .ma-t{
    margin-top: 58px;
  }
  .main-t{
    margin-top:28px;
  }
 
  .icn{
    display: flex;
  }
  .mainic{
    font-size: 17px;
  }
  .mainic p{
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 4px;
    margin-top: 0px;
    text-align: left;
    width: 100%;
  }
  .formMain input{
    color: #fff !important;
  }


  @media (min-width: 320px) and (max-width: 1024px) {
    .button.size200 {
      font-family: 'Montserrat', sans-serif;
      font-size: 14.5px;
      font-weight: 500;
      height: 45px;
      line-height: 1.48;
      padding-left: 24px;
      padding-right: 24px;
      text-transform: uppercase;
      margin-top: 20px;
  }

    .show-grid .maind {
      flex-direction: column;
  }
  .tuitiona-aid-sectionsInner {
    grid-template-columns: repeat(1, 1fr) !important;
}

  }