#root {
  max-width: 100%;
}

.chooseDiamond {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 75px;
  width: 300px;
  font-size: 16px !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: rgb(201,27,43, 0.2) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #c91b2b !important;
}

.currentPage {
  width: 100%;
  float: left
}

.rc-slider-track {
  background-color: #b9c1d6 !important;
}

.officeadd {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.rc-slider-step {
  height: 5px !important;
}

.mail {
  float: left;
  margin: 20px 0px;
  width: 100%;
}

.mail i {
  float: left;
  margin-right: 10px;
}

.person {
  margin: 10px 0px;
  width: 100%;
}

.person i {
  float: left;
  margin-right: 10px;
}

.rc-slider-handle {
  position: absolute;
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 32% !important;
  border: solid 2px #162345 !important;
  background-color: #162345 !important;
  touch-action: pan-x;
}

.rc-slider {
  width: 96% !important;
}

.rc-slider-mark-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.rc-slider-dot {
  border: 2px solid #b9c1d6 !important;
  background-color: #b9c1d6 !important;
}

#priceTooltipSpans-0, #LD_CaratTooltipSpans-0 {
  left: 0% !important;
}

#priceTooltipSpans-1 {
  left: 80% !important;
}

#LD_CaratTooltipSpans-1 {
  left: 95% !important;
}

#portraitAlert {
  display: none;
}

.reviewAnchor {
  font-style: italic;
  color: #c6a766;
}

.reviewAnchor:hover {
  color: #c6a766 !important;
}

.reviewedAt {
  margin: 30px 0 0 0;
}

.googleReview {
  font-family: 'Montserrat', sans-serif;
  text-decoration: underline;
  font-size: 16px;
  color: #c6a766;
}

.googleReview:visited, .googleReview:focus, .googleReview:active, .googleReview:hover {
  text-decoration: underline !important;
  color: #c6a766 !important;
}

.reviewDate {
  font-family: 'Montserrat', sans-serif;
  color: #555;
}

.reviewedText {
  height: auto;
  line-height: 20px;
}

@media only screen and (max-width: 1020px) and (min-width: 320px) {
  .consultation-form {
    margin-top: 60px;
  }
  #portraitAlert {
    display: none;
  }
  .covid19 p {
    font-size: 13px;
    line-height: 18px;
    padding: 5px 0px !important;
  }
  .process {
    width: 100% !important;
  }
  .covid19 {
    margin-top: 50px;
  }
  .bggg2_mobile {
    background: #fff !important;
    color: #000 !important;
    border: 1px solid #fff !important;
    padding: 7px 20px !important;
    border-radius: 16px !important;
    display: block !important;
    float: left;
    width: 100%;
  }
  .bggg2_mobile:hover {
    background: #fff !important;
    color: #000 !important;
  }
  .bggg2_mobile:active {
    background: #fff !important;
    color: #000 !important;
  }
  .bggg2_mobile:focus {
    background: #fff !important;
    color: #000 !important;
  }
  .bggg2 {
    display: none;
  }
  .videoSection p {
    font-family: 'Montserrat', sans-serif;
    width: 100% !important;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 16px;
  }
  .designaring {
    width: 50% !important;
  }
  .videoSection h1 {
    font-size: 18px !important;
    color: #b7800c;
    margin: 15px 0px;
    width: 100% !important;
    float: left;
  }
  .homeBgopacity p {
    font-family: 'Montserrat', sans-serif;
    width: 100% !important;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 16px;
    color: #fff;
  }
  .bggg {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  .bggg1 {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  .bggg2 {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  .loose ul {
    width: 100% !important;
    margin: 0 auto;
  }
  .watsApp {
    display: none;
  }
  .phoneData {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  #portraitAlert {
    display: none;
  }
}

@media only screen and (min-width: 375px) and (max-width: 1024px) and (orientation: landscape) {
  #portraitAlert {
    display: block;
    background: #212121;
    position: fixed;
    z-index: 9999999999;
    height: 100%;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .col.m12.l6.mobileNotDisplay, .mobileContentDisplay {
    width: 50% !important;
  }
  .mostpopular .owl-nav {
    display: block !important;
  }
  .search-home {
    top: 0px !important;
    right: 0px !important;
  }
  .search-inpt {
    width: 96% !important;
  }
  .search-home form {
    left: -195px !important;
    position: relative !important;
  }
}

/*LOGINBOX AND MINICART*/

.myaccount-box:hover .top-cart {
  display: block !important;
}

.nav-hover-1>li>a:hover {
  background: #fff !important;
  color: #000 !important;
  border-bottom: 3px solid #d69c28 !important;
}

.nav-hover-1>li>a {
  background: #fff !important;
  color: #000 !important;
  border-bottom: 3px solid #fff !important;
}

.footerDiv {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
}

.SortByData {
  display: block;
  margin-top: 0px;
  border: 1px solid rgb(226, 225, 225);
  width: 210px;
  float: right;
  margin-right: 0px;
  padding-right: 0px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  padding-left: 10px;
  height: 40px;
}

.mycart-box {
  margin-top: 2px;
}

.mycart-box:hover .top-cart {
  display: block !important;
}

.tooltipped {
  font-size: 21px !important;
}

.mobile_header {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
}

.bx-shdw {
  padding: 10px !important;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 10px 1px #cdcdcd;
  margin-top: 40px;
}

.all_services {
  font-size: 17px;
}

.vertSeparator {
  text-align: center;
  margin-top: 20px !important;
  margin-bottom: 40px;
}

#loginBox h2 {
  margin-bottom: 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.buttonPageActive {
  font-weight: 500;
  color: #fff;
  letter-spacing: .4px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  background-color: #162345;
  padding: 4px 10px 6px 10px;
  font-size: 16px !important;
  border: none;
  font-family: 'Montserrat', sans-serif;
}

.input-range__track--active {
  background: #b9c1d6 !important;
}

.input-range__slider {
  appearance: none;
  background: #162345 !important;
  border: 1px solid #162345 !important;
  border-radius: 30% !important;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -.5rem;
  margin-top: -.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform .3s ease-out, box-shadow .3s ease-out;
  width: 1rem;
}

.buttonPage {
  color: #282c3f;
  background: none;
  border: none;
  padding: 0px 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.buttonPageActive:focus {
  outline: none;
  background-color: #162345;
}

.buttonPage:focus {
  outline: none;
  background: none;
}

.buttonPageLeft {
  height: 42px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #d4d5d9;
  text-align: center;
  margin-left: 0px;
  padding: 10px 22px;
  background: none;
  font-size: 16px !important;
  /* border: none; */
  font-family: 'Montserrat', sans-serif;
  margin-right: 0px;
}

.x {
  height: 42px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #d4d5d9;
  text-align: center;
  margin-left: 14px;
  padding: 10px 22px;
  background: none;
  font-size: 16px !important;
  /* border: none; */
  font-family: 'Montserrat', sans-serif;
  margin-right: 10px;
}

.nextarrow {
  float: right;
  font-size: 18px;
  padding-top: 3px;
  padding-left: 3px;
}

.prevarrow {
  float: left;
  font-size: 18px;
  padding-top: 3px;
  padding-right: 3px;
}

.y {
  height: 43px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #d4d5d9;
  text-align: center;
  margin-left: 14px;
  padding: 10px 22px;
  background: none;
  font-size: 16px !important;
  /* border: none; */
  font-family: 'Montserrat', sans-serif;
  margin-right: 0px;
}

.y:hover {
  border: 1px solid #162345;
}

.y:focus {
  outline: none;
  background: none;
}

.x:focus {
  outline: none;
  background: none;
}

.buttonPageRight:focus {
  outline: none;
  background: none;
}

.buttonPageLeft:focus {
  outline: none;
  background: none;
}

.x:hover {
  border: 1px solid #162345;
}

.buttonPageRight:hover {
  border: 1px solid #162345;
}

.buttonPageLeft:hover {
  border: 1px solid #162345;
}

.buttonPageRight {
  height: 43px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #d4d5d9;
  text-align: center;
  margin-left: 14px;
  padding: 10px 22px;
  background: none;
  font-size: 16px !important;
  /* border: none; */
  font-family: 'Montserrat', sans-serif;
  margin-right: 0px;
}

.secondLevel42702 img {
  display: none;
}

.NoItems {
  text-align: center;
  color: #d69c28;
  font-weight: normal !important;
  font-size: 20px !important;
  font-family: 'Montserrat', sans-serif;
  margin-top: 30px;
}

.secondLevel42702 a {
  padding-left: 0 15px;
}

.services-right {
  background: none;
  border: none !important;
  margin: 10px 0 !important;
  outline: none !important;
  cursor: pointer !important;
  width: 100%;
  float: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}

.data-login {
  padding-bottom: 30px;
}

.login_registerbtn {
  margin-top: 15px;
}

.ml-input {
  height: 36px!important;
  border: 1px solid #b7b3b3 !important;
  padding-left: 10px!important;
  width: 96% !important;
  float: left!important;
  margin-bottom: 10px!important;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px !important;
}

.ml-input:active {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #b7b3b3 !important;
}

.ml-input:hover {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #b7b3b3 !important;
}

.ml-input:focus {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #b7b3b3 !important;
}

.facetsContent h4 {
  font-size: 14px;
  text-transform: uppercase;
}

.facetsscroll {
  height: 980px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.facetsscroll:hover {
  overflow-y: auto;
  overflow-x: hidden;
}

table {
  width: 100%;
  display: table;
  box-shadow: 0 0 10px #cdcdcd !important;
}

/*.Carat{
  overflow: hidden;
  height: 185px;
  overflow-x: hidden;
}
.Carat:hover{
  overflow-y: auto;
  overflow-x: hidden;
}

.Table {
  overflow-y: hidden;
  height: 185px;
  overflow-x: hidden;
}
.Table:hover{
  overflow-y: auto;
  overflow-x: hidden;
}
.Depth {
  overflow-y: hidden;
  height: 185px;
  overflow-x: hidden;
}
.Depth:hover{
  overflow-y: auto;
  overflow-x: hidden;
}*/

/* width */

td, th {
  padding: 10px 5px !important;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 0px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  font-family: 'Montserrat', sans-serif;
}

thead {
  border-bottom: 1px solid #d0d0d0;
  background: #dfb772 !important;
}

.input-range {
  position: relative;
  width: 80% !important;
  margin-left: 10% !important;
}

.input-range__label--min {
  display: none !important;
}

.input-range__label--max {
  display: none !important;
}

.reset {
  float: right;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  margin: 15px 0px;
}

.item-image {
  margin: 0 auto !important;
  background: #f1f1f1 !important;
  width: 100% !important;
  height: auto !important;
}

.dk_aboutus {
  line-height: 24px;
  text-align: justify;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.dk_aboutus_1 {
  line-height: 24px;
  text-align: justify;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding-left: 30px;
}

.Carat {
  margin-top: 15px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 50px;
}

figure {
  margin: 0;
  background-color: #cccccc;
}

.cre {
  float: left;
  text-align: left;
  background: #fff;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  padding: 10px 20px;
}

.Table {
  margin-top: 15px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 50px;
}

.Depth {
  margin-top: 15px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 50px;
}

.dk_aboutus a, .dk_aboutus_1 a {
  color: #d69c28 !important;
}

.statichead {
  font-size: 21px;
  text-align: left;
  border-left: 4px solid #d69c28;
  padding: 13px 0px 13px 10px;
  background: #fbfbfb;
  font-family: 'Montserrat', sans-serif;
}

.mt50 {
  margin-top: 15px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 15px;
}

.priceInfo {
  margin: 0px 0px 20px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 45px;
}

.mb7 {
  margin-bottom: 7px;
}

.productCount {
  font-family: 'Montserrat', sans-serif;
  float: right;
  margin-right: 10%;
  color: #828181;
}

.facetImg {
  float: left;
  margin-right: 7px;
  width: 30px;
  height: 30px;
}

.facetData1 {
  font-family: 'Montserrat', sans-serif;
  line-height: 30px !important;
}

.productCount1 {
  font-family: 'Montserrat', sans-serif;
  float: right;
  margin-right: 10%;
  color: #828181;
  line-height: 30px;
}

.facetData {
  font-family: 'Montserrat', sans-serif;
}

.input-range__label-container {
  top: 40px!important;
  left: -50%;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  color: #000!important;
  font-size: 15px;
}

.content a:hover {
  color: #dca535 !important;
}

.sidenav-search {
  display: none;
}

.fixed-action-btn {
  display: none;
}

.megamenu2-dynamic {
  margin: -14px;
  padding: 0;
  float: right;
}

.page-title h1 {
  font-size: 24px !important;
  margin: 0 !important;
  color: #393838 !important;
  font-weight: 500 !important;
}

#Rings {
 
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 520px 397px;
  height: 300px;
}

#Gifting {
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 1155px 300px;
  height: auto;
}

#Diamonds {
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 520px 397px;
  height: 300px;
}

#Collections {

  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 520px 397px;
  height: 300px;
}

#DesignYourRing {
  /* background-image: url(https://cfcdn9.candere.com/media/custom/banners/File-1566216334.gif?v=1566216334); */
  background-position-y: bottom;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: 520px 397px;
  height: 300px;
}

.secondLevelDesign .Design {
  margin-top: 10px !important;
}

.secondLevel41206 {
  width: 27% !important;
  float: left !important;
  margin-left: 2%!important;
}

.secondLevelDesign {
  width: 30% !important;
  float: left !important;
}

.secondLevel42709 {
  width: 30%!important;
}

.tt_heading {
  font-size: 18px!important;
  font-weight: 500;
  color: #000;
  margin-bottom: 20px;
  margin-top: 20px;
}

.secondLevelEnchanted {
  display: none;
}

.Loose {
  width: 21% !important;
  float: left!important;
  margin-bottom: 5px;
}

.Loose p {
  width: 60%;
  float: left;
  text-align: center;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  height: 30px !important;
  vertical-align: top;
  position: relative;
}

.catImgss {
  float: left;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.subchilds {
  padding-left: 0px !important;
}

.subchilds p {
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
}

.secondLevel43953 {
  width: 20% !important;
  float: left !important;
  margin-left: 2% !important;
}

.secondLevel43498 {
  width: 30% !important;
  float: left !important;
  margin-left: 2% !important;
}

.secondLevel43498 .Her {
  width: 50%;
  float: left;
}

.secondLevel42702 {
  width: 50% !important;
  float: left !important;
  margin-left: 2% !important;
}

.secondLevelEveryday {
  width: 18% !important;
  float: left !important;
  margin-left: 2% !important;
  position: absolute !important;
  left: 0px !important;
  bottom: 50px !important;
}

.alignRight {
  text-align: right;
  float: right;
}

#Happy-Customers .owl-nav {
  display: none !important;
}

.happycustomers .carousel {
  height: 300px !important;
}

.featuredProducts {
  margin-top: 20px
}

.storeitem:hover {
  box-shadow: 0 0 10px #cdcdcd;
}

.megamenu2-dynamic {
  margin: -14px;
  padding: 0;
  float: right;
}

.subcat-Loose {
  display: none;
}

.subcat-Design {
  display: none;
}

.subcat-Diamond {
  display: none;
}

.subcat2 {
  text-align: center;
  width: 33%;
  float: left;
}

.image_class {
  text-align: center;
}

.education-How {
  width: 100% !important;
}

.education-Diamond {
  width: 100% !important;
}

.stander.Advice {
  display: block !important;
}

#owl-banners .owl-dots {
  display: none;
}

.owl-theme .owl-nav {
  margin-top: 0px !important;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: transparent !important;
}

#owl-banners .owl-next span {
  font-size: 55px;
  color: transparent;
}

#owl-banners .owl-prev span {
  font-size: 55px;
  color: transparent;
}

.owl-next span:hover {
  color: #d6a95d !important;
}

.owl-prev span:hover {
  color: #d6a95d !important
}

.owl-nav:hover {
  color: #d6a95d !important
}

.mobileNoDis .owl-prev {
  top: 200px !important;
}

.mobileNoDis .owl-next {
  top: 200px !important;
}

.subcat-Loose {
  display: none;
}

.subcat-Design {
  display: none;
}

.subcat-Diamond {
  display: none;
}

.subcat2 {
  text-align: center;
  width: 33%;
  float: left;
}

.image_class {
  text-align: center;
}

.education-How {
  width: 100% !important;
}

.education-Diamond {
  width: 100% !important;
}

.stander.Advice {
  display: block !important;
}

#owl-banners .owl-dots {
  display: none;
}

.owl-theme .owl-nav {
  margin-top: 0px !important;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: transparent !important;
}

#owl-banners .owl-next span {
  font-size: 55px;
  color: #ede7e7 !important;
}

#owl-banners .owl-prev span {
  font-size: 55px;
  color: #ede7e7 !important;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  background-color: #000 !important;
  position: initial !important;
  overflow: visible !important;
}

.owl-next span:hover {
  color: #ede7e7 !important
}

.owl-prev span:hover {
  color: #ede7e7 !important
}

.owl-nav:hover {
  color: #ede7e7 !important
}

.owl-prev {
  top: 190px !important;
}

.owl-next {
  top: 190px !important;
}

.p46 {
  padding-left: 65px !important;
}

.p36 {
  padding-left: 25px !important;
}

.overlayDesktop {
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
  position: fixed;
}

#ttl {
  display: none !important;
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
  .mobileLogo {
    width: auto !important;
    position: absolute;
    top: 0px !important;
    margin: 0;
    left: 80px !important;
  }
  nav .sidenav-trigger i {
    height: 55px !important;
    line-height: 55px !important;
    color: #162345 !important;
  }
}

.happycustomers item a:hover {
  color: #000 !important;
}

#Mobile-owl-banners {
  display: none;
}

.bb {}

.mobileNoDis {
  display: block;
}

.mobileYesDis {
  display: none;
}

.lineh {
  line-height: 43px !important;
  font-size: 16px !important;
}

.content a {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

#main-menu i {
  COLOR: #000;
}

.stellarnav, .stellarnav li {
  position: relative;
  line-height: normal
}

.fa-plus {
  display: none !important;
}

.engagedata {
  margin-top: 10px !important;
}

.mobile-head {
  float: left;
  width: 100%;
}

.mobile-li {
  width: 48%;
  float: left !important;
  /* border: 1px solid #cdcdcd; */
  height: auto;
  padding: 2px !important;
  border-radius: 2px;
  margin: 1% !important;
  /* box-shadow: 0 0 10px #cdcdcd; */
  background: #fff;
}

.mobile-aa {
  width: 100%;
  float: left;
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* display: table !important; */
  /* display: flex !important; */
  /* justify-content: center; */
  /* align-items: center; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: baseline; */
  /* width: 100%; */
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.mobile-imgg {
  width: 30px;
  float: left;
  padding-top: 13px;
  margin-right: 10px;
}

.mobile-pp {
  float: left;
  width: 100%;
  line-height: 17px;
  font-size: 11px;
  font-weight: normal;
  text-align: center;
  /* vertical-align: middle; */
  /* display: inline-block; */
  /* vertical-align: middle; */
  /* position: absolute; */
  /* top: 0; */
  /* bottom: 0; */
  /* left: 40%; */
  /* right: 0%; */
  /* width: 51%; */
  /* height: 45%; */
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 3px;
  margin-top: 0px;
}

.mobile-2 {
  padding-top: 0px;
  color: #113d6b;
  font-size: 34px;
  /* width: 20px; */
  /* border: 1px solid #f99d27; */
  /* padding: 0px; */
  margin-top: 0px;
  /* margin-right: 10px; */
  float: right;
  /* border-radius: 4px; */
}

.mobile-5 {
  padding-top: 13px;
  color: #f99d27;
  font-size: 20px;
}

.mobile-4 {
  padding-left: 30px !important;
  font-size: 12px !important;
}

.mobile-6 {
  padding-left: 40px !important;
  font-size: 12px !important;
}

.stellarnav {
  width: 100%;
  z-index: 9900
}

.stellarnav ul {
  margin: 0;
  padding: 0;
  text-align: center
}

.stellarnav li {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

.stellarnav li a {
  display: block;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  transition: all .3s ease-out;
  text-align: left;
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}

.stellarnav>ul>li {
  display: inline-block
}

.stellarnav>ul>li>a {
  padding: 20px 40px
}

.stellarnav ul ul {
  width: 220px;
  position: relative;
  display: none;
}

.stellarnav li li {
  display: block
}

.stellarnav .dd-toggle, .stellarnav li.call-btn-mobile, .stellarnav li.location-btn-mobile, .stellarnav.hide-arrows li li.has-sub>a:after, .stellarnav.hide-arrows li.drop-left li.has-sub>a:after, .stellarnav.hide-arrows li.has-sub>a:after {
  display: none
}

.stellarnav>ul>li:hover>ul>li:hover>ul {
  opacity: 1;
  visibility: visible;
  top: 0
}

.stellarnav>ul>li.drop-left>ul {
  right: 0
}

.stellarnav li.drop-left ul ul {
  left: auto;
  right: 220px
}

.stellarnav.light, .stellarnav.light ul ul {
  background: none;
}

.stellarnav.light li a {
  color: #000
}

.stellarnav.light .call-btn-mobile, .stellarnav.light .location-btn-mobile, .stellarnav.light .menu-toggle {
  color: rgba(0, 0, 0, 1)
}

.stellarnav.dark, .stellarnav.dark ul ul {
  background: rgba(0, 0, 0, 1)
}

.stellarnav.dark li a {
  color: #FFF
}

.stellarnav.dark .call-btn-mobile, .stellarnav.dark .location-btn-mobile, .stellarnav.dark .menu-toggle {
  color: rgba(255, 255, 255, 1)
}

.stellarnav.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999
}

body.stellarnav-noscroll-x {
  overflow-x: hidden
}

.stellarnav li.has-sub>a:after {
  content: '\f0d7';
  font-family: 'Montserrat', sans-serif;
  margin-left: 10px
}

.stellarnav li li.has-sub>a:after {
  content: '\f0da';
  font-family: 'Montserrat', sans-serif;
  margin-left: 10px
}

.stellarnav li.drop-left li.has-sub>a:after {
  float: left;
  content: '\f0d9';
  font-family: 'Montserrat', sans-serif;
  margin-right: 10px
}

.stellarnav .call-btn-mobile, .stellarnav .close-menu, .stellarnav .location-btn-mobile, .stellarnav .menu-toggle {
  display: none;
  text-transform: uppercase;
  text-decoration: none
}

@media only screen and (min-width:768px) and (max-width :1023px) {
  .mobile-li {
    width: 35%;
  }
}

@media only screen and (min-width:1024px) and (max-width :1365px) {
  .stellarnav ul ul {
    width: 500px;
  }
  .stellarnav li a {
    font-size: 24px;
  }
}

@media only screen and (max-width :1279px) {
  .stellarnav {
    overflow: hidden;
    display: block
  }
  .statichead {
    margin-top: 80px;
  }
}

.stellarnav .call-btn-mobile, .stellarnav .location-btn-mobile, .stellarnav .menu-toggle {
  color: #777;
  padding: 15px;
  box-sizing: border-box
}

.stellarnav .full {
  width: 100%
}

.stellarnav .half {
  width: 50%
}

.stellarnav .third {
  width: 33%;
  text-align: center
}

.stellarnav .location-btn-mobile.third {
  text-align: center
}

.stellarnav .location-btn-mobile.half {
  text-align: right
}

.stellarnav.light .half, .stellarnav.light .third {
  border-left: 1px solid rgba(0, 0, 0, .15)
}

.stellarnav.light .half:first-child, .stellarnav.light .third:first-child {
  border-left: 0
}

.stellarnav.dark .half, .stellarnav.dark .third {
  border-left: 1px solid rgba(255, 255, 255, .15)
}

.stellarnav.dark .half:first-child, .stellarnav.dark .third:first-child {
  border-left: 0
}

.stellarnav.mobile, .stellarnav.mobile.fixed {
  position: static
}

.stellarnav.mobile.active>ul, .stellarnav.mobile>ul>li {
  display: block
}

.stellarnav.mobile.active {
  padding-bottom: 0
}

.stellarnav.mobile>ul>li>a {
  padding: 15px
}

.stellarnav.mobile ul ul {
  position: relative;
  opacity: 1;
  visibility: visible;
  width: auto;
  display: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: color 0 ease-in;
  transition: none
}

.stellarnav.mobile ul ul ul {
  left: auto;
  top: auto
}

.stellarnav.mobile li.drop-left ul ul {
  right: auto
}

.stellarnav.mobile li a {
  border-bottom: 1px solid rgba(255, 255, 255, .15)
}

.stellarnav.mobile li.has-sub a {
  padding-right: 50px
}

.stellarnav.mobile>ul {
  border-top: 1px solid rgba(255, 255, 255, .15)
}

.stellarnav.mobile.light li a {
  border-bottom: none;
}

.stellarnav.mobile.light>ul {
  border-top: 0px solid rgba(0, 0, 0, .15)
}

.stellarnav.mobile li a.dd-toggle, .stellarnav.mobile.light li a.dd-toggle {
  border: 0
}

.stellarnav.mobile .call-btn-mobile, .stellarnav.mobile .close-menu, .stellarnav.mobile .dd-toggle, .stellarnav.mobile .location-btn-mobile, .stellarnav.mobile .menu-toggle {
  display: inline-block
}

.stellarnav.mobile li.call-btn-mobile {
  border-right: 1px solid rgba(255, 255, 255, .1);
  box-sizing: border-box
}

.stellarnav.mobile li.call-btn-mobile, .stellarnav.mobile li.location-btn-mobile {
  display: inline-block;
  width: 50%;
  text-transform: uppercase;
  text-align: center
}

.stellarnav.mobile li.call-btn-mobile.full, .stellarnav.mobile li.location-btn-mobile.full {
  display: block;
  width: 100%;
  text-transform: uppercase;
  border-right: 0;
  text-align: left
}

.stellarnav.mobile li.call-btn-mobile i, .stellarnav.mobile li.location-btn-mobile i {
  margin-right: 5px
}

.stellarnav.mobile.light ul {
  background: none;
}

.stellarnav.mobile.dark ul {
  background: rgba(0, 0, 0, 1)
}

.stellarnav.mobile.dark ul ul {
  background: none;
}

.stellarnav.mobile.light li.call-btn-mobile {
  border-right: 1px solid rgba(0, 0, 0, .1)
}

.stellarnav.mobile.top {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999
}

.stellarnav.mobile li li.has-sub>a:after, .stellarnav.mobile li.drop-left li.has-sub>a:after, .stellarnav.mobile li.has-sub>a:after {
  display: none
}

@media only screen and (max-width :420px) {
  .stellarnav.mobile .call-btn-mobile.third span, .stellarnav.mobile .location-btn-mobile.third span {
    display: none
  }
}

.MDisplay {
  display: none;
}

.DDisplay {
  display: block;
}

.TOInput {
  color: #fff !important;
  padding-left: 10px !important;
}

.fontmerri {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.returns a:hover {
  color: #fff !important;
}

.menuData .Ring {
  display: none;
}

.menuData .Buying {
  display: none;
}

.menuData .Clarity {
  display: none;
}

.menuData .Carat {
  display: none;
}

.menuData .How {
  display: none;
}

.menuData .Cut {
  display: none;
}

.menuData .Shape {
  display: none;
}

.menuData .Color {
  display: none;
}

.sub-cat-img img {
  width: 60px;
  height: 60px;
}

.subcat-4 {
  display: none;
}

.subcat-Metal {
  display: none;
}

.subcat-Ring {
  display: none;
}

.subcat-Jewellery {
  display: none;
}

.subcat2 img {
  width: 60px;
  height: 60px;
}

.Three .subcatimg {
  display: none;
}

.Gifts .subcatimg {
  display: none;
}

.Designer .subcatimg {
  display: none;
}

.Solitaires .subcatimg {
  display: none;
}

.Eternity .subcatimg {
  display: none;
}

.Two .subcatimg {
  display: none;
}

.Him .subcatimg {
  display: none;
}

.Her .subcatimg {
  display: none;
}

.Wedding .subcatimg {
  display: none;
}

.content li i {
  font-size: 27px;
  margin-right: 10px;
}

.pl0 {
  padding-left: 0px !important;
}

#myUber a i {
  display: none;
}

.mobileLogo {
  display: none;
}

.DyoRingsMobile {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) {
  .p46 {
    padding-left: 0px !important;
  }
  .DyoRings {
    margin-top: 10px !important;
    display: none;
  }
  .DyoRingsMobile {
    margin-top: 10px !important;
    display: block;
  }
  .DyoFacetsData.Shape {
    margin-top: 40px;
  }
  .DyoF {
    top: 189px !important;
  }
  .chooseDiamond {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 60px;
    width: 300px;
    font-size: 16px !important;
  }
  .user-profile {
    display: none !important;
  }
  .x span {
    display: none;
  }
  .y span {
    display: none;
  }
  .page-title h1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .MobileContainer {
    width: 100% !important;
  }
  .reset {
    float: left;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin: 15px 0px 15px -35px;
    width: 100%;
    padding-top: 50px;
    text-align: right;
  }
  .priceInfo {
    margin: 0px 0px 20px;
    float: left;
    width: 90% !important;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 45px;
    position: relative !important;
    left: 5% !important;
  }
  .MobileFacetsTable {
    display: none !important;
  }
  .MobileFacetsDepth {
    display: none !important;
  }
  #testHead {
    z-index: 9999999999999 !important;
  }
  .mt50 {
    margin-top: 15px;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 15px;
    position: relative !important;
    width: 90% !important;
    left: 5% !important;
    float: left !important;
  }
  #Carat {
    padding-bottom: 50px !important;
  }
  .bx-shdw {
    margin-top: 80px !important;
  }
  #loginBox h2 {
    font-size: 18px;
  }
  .all_services {
    font-size: 16px;
  }
  .SortByData {
    float: left;
    width: auto;
  }
  .ppr {
    margin-top: 10px !important;
  }
  .numberOfItem1 {
    padding-left: 0px !important;
  }
  .scrollup {
    display: none !important;
  }
  .owl-nav {
    display: none;
  }
  .MobileSocial {
    display: inline-block;
    width: auto !important;
    vertical-align: middle;
    margin-top: 10px;
    float: none !important;
  }
  .LoginSign {
    position: relative;
    top: 12px;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    font-size: 18px;
    left: -10px !important;
  }
  .dividers {
    display: none;
  }
  .trackButton {
    margin: 10px 0px 10px 0px !important;
  }
  .mmb {
    margin-bottom: 15px !important;
  }
  .pt8 {
    padding-top: 8px !important;
  }
  #welcome-data-mobile a {
    color: #333 !important;
    background: none;
    font-size: 16px;
    padding: 0!important;
    position: relative;
    top: 0px;
  }
  #welcome-data-mobile a img {
    margin-right: 20px;
  }
  .sidenav-search:focus {
    color: #fff !important;
  }
  .pbb {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 25px;
  }
  .log {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
  }
  .logBg {
    background-color: #f9f9f9;
    margin-bottom: 0px;
    padding: 0px;
    height: 50px;
    border-bottom: 1px solid #d4d5d9;
    border-top: 1px solid #d4d5d9;
  }
  .owl-prev span {
    font-size: 55px;
    color: #d6a95d !important;
  }
  .owl-next span {
    font-size: 55px;
    color: #d6a95d !important;
  }
  .fs16 {
    font-size: 15px !important;
  }
  .homecenter {
    text-align: left !important;
  }
  .fixed-action-btn {
    display: block !important;
  }
  .mobileNoDis {
    display: none !important;
  }
  #Mobile-owl-banners .owl-nav {
    display: none;
  }
  #Mobile-owl-banners .owl-dots {
    display: none;
  }
  .mobileYesDis {
    display: block !important;
    margin-top: 0px;
    /* margin-top: 50px; */
  }
  nav .sidenav-trigger {
    padding-top: 0px !important;
  }
  .mt80 {
    margin-top: 0px !important;
  }
  .loose {
    padding-top: 0px !important;
  }
  .subcatData {
    /* background: #393838; */
    position: relative;
    top: 0px !important;
  }
  .looseDiamndList {
    float: left;
    width: auto;
    text-align: center;
    margin: 0px 7px !important;
  }
  .menuData {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
  }
  .designaring {
    text-align: center !important;
    ;
  }
  .img-width1 {
    width: 60px;
    padding-top: 10px;
    float: left;
  }
  .footerh {
    text-align: left;
    font-weight: bold;
  }
  .owl-carousel .owl-item img {}
  .sidenav-search {
    display: block !important;
  }
  .MCenter {
    text-align: center !important;
  }
  .Details {
    display: none;
  }
  .MDisplay {
    display: block !important;
  }
  .DDisplay {
    display: none;
  }
  .owl-prev {
    position: absolute;
    left: 0px;
    top: 200px !important;
  }
  .sidenav-search {
    float: right;
    padding-top: 6px;
    color: #fff !important;
  }
  .owl-next {
    top: 200px !important;
  }
  .pds0 {
    padding: 0px !important;
  }
  .homecenter {
    font-size: 19px !important;
    line-height: 33px!important;
    margin-bottom: 10px;
    margin-top: 0;
    text-align: justify !important;
  }
  .content a {
    text-align: left;
    font-size: 17px !important;
  }
  .leButton {
    font-size: 17px !important;
  }
  .reviewdata {
    font-size: 21px !important;
  }
  .torder {
    font-size: 14px !important;
    text-align: right;
  }
  .trackButton {
    font-size: 14px !important;
  }
  .le-con {
    font-size: 21px !important;
  }
  .customerName {
    font-size: 21px !important;
  }
  .customerLocation {
    font-size: 21px !important;
  }
  .content li {
    text-align: left;
  }
  .menuData {
    overflow-x: scroll;
    display: flex;
    margin-bottom: 0px;
    padding-top: 5px!important;
    margin-top: 0px !important;
  }
  .guarantee {
    font-size: 21px!important;
    width: 100%;
    float: left;
  }
  .Loose {
    width: 1000px;
    margin-top: 20px;
  }
  .designaring {
    font-size: 14px !important;
    position: relative;
    top: 0px;
  }
  .mobileLogo {
    width: 50%;
    padding-top: 10px;
    float: left;
    display: block !important;
  }
  .light-blue.lighten-1 {
    background-color: #fff!important;
    height: 55px !important;
  }
  .mobile_hide {
    display: none;
  }
  .logoSec {
    display: none !important;
  }
  .logoSec img {
    display: none !important;
  }
  .mobile_header {
    background: #000 !important;
    height: 64px;
    line-height: 64px;
  }
  .rightsieimg {
    background: transparent !important;
  }
  .homeBgopacity {
    height: 450px !important;
    padding-bottom: 50px !important;
  }
  .mt130 {
    margin-top: 40px !important;
  }
  .Crafting {
    color: #bc881c;
    font-size: 23px !important;
    font-style: italic;
    font-weight: normal !important;
    line-height: 41px;
    word-spacing: 0px !important;
    margin: 0px;
  }
  .bg_center {
    display: none;
  }
  .diabg {
    display: none;
  }
  .p36 {
    padding-left: auto !important;
  }
  .looses {
    margin: 0px 0px !important;
  }
  .mobilecss {
    width: 100%;
  }
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.footerh {
  color: #000;
  font-weight: 500;
  text-align: left;
}

.returns a:focus {
  color: #fff !important;
  font-size: 13px;
}

.lazyBg {
  background: #ffecb5;
  float: left;
  height: 30px;
  width: 30px;
}

.lazyBg img {
  background: #ffecb5;
  float: left;
  height: 30px;
  width: 30px;
}

.rightsieimg {
  background-repeat: no-repeat;
  right: 0px;
  float: right;
}

.mt80 {
  margin-top: 80px;
}

.mt130 {
  margin-top: 120px;
}

.product-price {
  font-style: italic;
  margin: 10px 0px;
  font-size: 16px;
}

.product-title:hover {
  color: #c6a766;
}

.product-description:hover {
  color: #c6a766;
}

.product-info {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.featuredProduct {
  text-align: center;
}

.product-title {
  font-size: 18px;
}

.bg_center {
  position: absolute;
  z-index: -1;
  margin-top: 280px;
}

.light-blue.lighten-1 {
  background-color: #fff;
}

.logoSec {
  line-height: 40px !important;
  padding-top: 10px !important;
  display: list-item;
  text-align: center;
}

.logoSec img {
  width: 270px;
  vertical-align: middle;
  margin-top: 10px;
}

.brown-bg {
  background-color: #162345;
  color: #fff;
  line-height: initial;
  margin-bottom: 0px !important;
}

a:hover, a:focus {
  text-decoration: none !important;
}

.socialMedia ul {
  float: right;
  width: auto;
  margin: 0 auto;
  z-index: 1000;
}

.owl-next {
  position: absolute;
  right: 0px;
  top: 30vw !important;
}

#featured .owl-dots {
  display: none;
}

.owl-next span {
  font-size: 55px;
  color: #ede7e7;
}

.owl-prev {
  position: absolute;
  left: 0px;
  top: 30vw !important;
}

.owl-prev span {
  font-size: 55px;
  color: #ede7e7;
}

.owl-theme .owl-dots {
  margin-top: 10px !important;
  margin-bottom: 20px;
  position: relative;
  top: 0px;
}

.subcatData {
  /* background: #393838; */
  position: relative;
  top: -1px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #d69c28 !important;
}

.owl-theme .owl-dots .owl-dot span {
  width: 20px !important;
  height: 10px;
}

.owl-theme .owl-dots .owl-dot span {
  background: #ebd3a4 !important;
}

.firstItem {
  width: 90%;
  float: left;
  padding: 30px;
  height: 320px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}

.page-breadcrumb .text-link {
  width: auto;
  float: none;
  color: #979797 !important;
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif;
}

.page-footer {
  box-shadow: 0 0 4px #cdcdcd;
}

.text-link {
  font-size: 17px;
  color: #000 !important;
  text-align: center;
  width: 100%;
  float: left;
  margin: 7px 0px;
}

.text-link-price {
  font-size: 17px;
  color: #000 !important;
  text-align: center;
  width: 100%;
  float: left;
  margin: 7px 0px;
  font-style: italic;
}

.hoverimg:hover {
  box-shadow: 0 0 10px #cdcdcd;
}

.text-link:hover {
  color: #c6a766 !important;
}

.text-link:focus {
  color: #c6a766 !important;
}

.text-link-price {
  color: #000 !important;
}

.happy {
  text-align: center;
  font-style: italic;
  margin: 30px 0px 10px 0px;
}

.reviewdata {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  font-size: 16px;
  margin-top: 3%;
  font-family: 'Montserrat', sans-serif;
}

.diamondinner {
  position: absolute;
  right: 0px;
  top: 2177px;
}

.loaderClass {
  /* position: fixed;  or absolute 
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      background: rgba(256, 256, 256, 0.7);
      z-index: 999;
    }
    .loaderClass img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);*/
}

.page-breadcrumb {
  margin-top: 20px;
}

.borderTop {
  border-top: 1px solid #e2e1e1;
  margin-bottom: 50px;
}

#diamond {
  padding-top: 20px;
}

.pr0 {
  padding-right: 0px !important;
}

.ppr {
  padding-right: 0px !important;
  margin-bottom: 10px !important;
}

.numberOfItems {
  text-align: center;
  font-size: 16px;
  line-height: 35px;
  padding-top: 0px;
  font-family: 'Montserrat', sans-serif;
  color: #162345;
}

.numberOfItem {
  margin-top: 60px;
  text-align: left;
}

.numberOfItem1 {
  margin-top: 0px;
  text-align: left;
}

.headerShadow {
  box-shadow: 0 10px 20px -10px #cdcdcd;
}

.paginationData {
  text-align: center;
  margin-top: 50px;
}

.paginationDataDyo {
  text-align: center;
  margin-top: 0px;
}

.homeBg {
 
  background-color: #cccccc;
  margin-bottom: 0px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-repeat: round;
}

.learningBg {
  background-image: url("");
  background-color: #cccccc;
  margin-top: 0px;
  padding-bottom: 50px;
  margin-bottom: 0px;
}

.homeBgopacity {
  background-color: rgba(30, 40, 72, 0.8);
  padding: 30px 0px;
  margin: 0px 0px;
  height: 300px;
  padding-bottom: 0px;
}

.homeBgopacity p {
  font-family: 'Montserrat', sans-serif;
  width: 585px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 16px;
  color: #fff;
}

.guarantee {
  font-size: 21px;
  color: #fff;
  margin: 20px 0px;
  text-align: center;
}

.learning {
  font-size: 25px;
  margin: 1.14rem 0 .912rem 0;
  text-align: center;
}

.track {
  background: #000;
  margin-bottom: 0px;
}

.torder {
  color: #fff;
  padding-top: 19px !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.le-he {
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 20px 0px 5px 0px;
}

footer.page-footer {
  background: url(../img/partners/footer.webp);
  /* margin-top: 20px; */
  background-position: bottom;
  background-size: cover;
}
.floatingIcon{
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 999999;
}
.floatingIcon img{
  width: 50px !important;
  height: 50px !important;
}

.floatingIcon div:nth-of-type(2) {
  background: none !important;

}
.floatingIcon div:nth-of-type(1) {
 
    background-position: bottom;
    background-size: contain;
}
.floatingIcon div:nth-of-type(3) {
  background: none !important;

}

.floatingIcon span div:nth-of-type(1) {
  background: none !important;

}

.le-con {
  font-size: 16px;
  width: 80%;
  margin-left: 10%;
  margin-top: 3%;
  font-family: 'Montserrat', sans-serif;
}

.leButton {
  text-transform: capitalize !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  padding: 4px 15px !important;
  border-radius: 16px !important;
  margin: 10px 0px !important;
  background: transparent !important;
  line-height: 23px !important;
  height: auto !important;
  font-family: 'Montserrat', sans-serif;
}

.trackButton {
  text-transform: capitalize !important;
  color: #162345 !important;
  border: 1px solid #fff !important;
  padding: 4px 20px !important;
  border-radius: 16px !important;
  margin: 12px 0px 10px 0px;
  line-height: 23px !important;
  height: auto !important;
  background: #fff !important;
  font-family: 'Montserrat', sans-serif;
}

.mb30 {
  margin-bottom: 30px;
}

.customerName {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  font-size: 16px;
  margin-top: 25px;
  font-family: 'Montserrat', sans-serif;
}

.happycustomers .owl-dots {
  position: relative;
  top: 30px;
}

.customerLocation {
  width: 80%;
  text-align: center;
  margin-left: 10%;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.text-link-price:hover {
  color: #c6a766 !important;
}

.secondItem {
  width: 90%;
  float: left;
  padding: 30px;
  height: 320px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}

.returns {
  line-height: 27px;
  font-size: 12px;
  float: left;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
}

.returns a {
  color: #fff;
  font-size: 13px;
}

.thirdItem {
  width: 90%;
  float: left;
  padding: 30px;
  height: 320px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}

.homecontent {
  width: 400px;
  margin: 0 auto;
}

.returnPolicy i {
  width: 100%;
  font-size: 36px;
  color: #d69c28;
}

.looseDiamndList {
  float: left;
  width: auto;
  text-align: center;
  margin: 0 20px 0 13px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb5 {
  margin-bottom: 5px;
}

.dividers {
  text-align: center;
}

.customcss {
  font-size: 25px;
  text-transform: capitalize;
}

.looking {
  text-transform: capitalize;
  font-size: 13px;
  margin-top: 20px;
  letter-spacing: 0.5px;
}

.homecenter {
  text-align: justify;
  line-height: 23px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.Crafting {
  color: #bc881c;
  font-size: 32px;
  font-style: italic;
  font-weight: bold;
  line-height: 41px;
  word-spacing: 15px;
}

.react-tooltip-lite {
  background: #d69c28!important;
  color: #fff!important;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
}

.react-tooltip-lite-arrow {
  display: none !important;
}

.mr7 {
  margin-right: 7px;
}

.menuData {
  padding-bottom: 0px !important;
  padding-top: 15px;
  margin-top: 15px;
}

.looseDiamndList>.backdrop {
  background: #f00;
}

.looseDiamndList img {
  width: auto;
}

.looses {
  margin: 50px 0px;
  float: left;
}

.HomerCenterImg3 img {
  width: 98%;
  height: 225px;
}

.content {
  width: 100%;
  float: left;
  padding: 20px 0px;
  font-family: 'Montserrat', sans-serif;
}
.itemData {
  width: 60% !important;
  margin: 0 auto;
}

.footer_logo {
  padding-top: 3% !important;
}

.footer_logo1 {
  padding-top: 3% !important;
  text-align: center;
}

.footer_logo1 img {
  width: auto;
}

.video-container {
  box-shadow: 0 0 10px #cdcdcd !important;
}

.HomerCenterImg2 img {
  width: 96%;
  height: 220px;
  margin-bottom: 10px;
}

.HomerCenterImg2 {
  padding-left: 0px;
  padding-right: 0px;
}

.HomerCenterImg3 {
  padding-left: 0px;
  padding-right: 0px;
}

.HomerCenterImg {
  padding-left: 0px;
  padding-right: 0px;
}

.HomerCenterImg img {
  width: 100%;
}

.designaring {
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding: 15px 0px 25px 0px !important;
  min-height: auto !important;
  width: 200px;
  float: left;
}

.designaring img {
  position: relative;
  right: 10px;
  top: 7px;
}

.looseDiamndList p {
  width: 100%;
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
}

.borderlines {
  border-top: 2px solid #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.socialMedia ul li {
  float: right;
  width: auto;
  margin: 0px 8px;
  position: relative;
  top: 5px;
}

.socialMedia ul li span {
  background: #c6a766;
  padding: 1px 5px 1px 5px;
  border-radius: 50%;
  font-size: 10px;
  position: absolute;
  top: -3px;
  right: -12px;
}

.m10 {
  margin: 10px 0px;
}

/*----------------------------------------
    Primary Nav 
  ------------------------------------------*/

.login-box-inline {
  margin: 0px;
  line-height: 20px;
}

.primary-nav {
  justify-self: flex-end;
  position: static !important;
}

.primary-nav .active {
  background-color: transparent;
}

.primary-nav li {
  position: relative;
}

.primary-nav li a {
  display: block;
  font-weight: 400;
  position: relative;
}

.primary-nav.center {
  float: none;
}

.primary-nav.center .menu-list>li {
  float: none;
  display: inline-block;
}

.menu-list>li {
  float: left;
  position: relative;
}

.menu-list>li>a {
  font-size: 14px;
  padding: 0 14px;
  position: relative;
  text-transform: capitalize;
}

.menu-list li.megamenu {
  position: static;
  padding: 0 0px;
}

.mb0 {
  margin-bottom: 0px !important;
}

.search-home {
  border: 1px solid #162345;
  height: 30px;
  position: relative;
  top: 18px;
  border-radius: 32px;
  right: -16px;
}

.search-home img {
  float: left;
  position: relative;
  top: 4px;
  left: 8px;
}

.search-inpt {
  float: right;
  height: 28px !important;
  font-size: 16px !important;
  padding-left: 10px !important;
  color: #000 !important;
  width: 75% !important;
  font-family: 'Montserrat', sans-serif;
}

.search-inpt:focus {
  border: none !important;
  box-shadow: none !important;
}

/*----------------- DropDown ---------------*/

.menu-list .sub-menu {
  background-color: #fff;
  left: 5%;
  min-width: 190px;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: 219%;
  visibility: hidden;
  white-space: nowrap;
  width: 90%;
  z-index: 12;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  box-shadow: 0px 10px 20px -10px #cdcdcd;
}

.menu-list .sub-menu>li>a {
  line-height: inherit;
  font-size: 15px !important;
  color: #d69c28 !important;
  font-family: 'Montserrat', sans-serif;
}

.menu-list .sub-menu .sub-menu {
  top: 0;
  left: 100%;
}

.has-dropdown:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  z-index: 10000;
}

.has-dropdown .dropdown-holder {
  display: none;
}

.sub-menu>li .arrow {
  font-size: 16px;
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sub-menu.megamenu-wrapper {
  min-width: auto;
  padding: 0px;
  font-size: 16px;
  text-transform: capitalize;
}

.sub-menu.megamenu-wrapper>li {
  width: 100%;
}

.sub-menu.megamenu-wrapper>li a {
  font-size: 16px;
  text-transform: capitalize;
}

.sub-menu.megamenu-wrapper>li a:focus {
  color: #fff;
  opacity: 0.6;
}

.megamenu-child {
  font-size: 15px !important;
  min-width: 180px;
  margin-left: 10px;
  color: #999 !important;
  margin-top: -10px;
}

.megamenu-child li {
  line-height: 25px;
  font-weight: bold;
}

.megamenu-child .megamenu-title {
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 10px;
  padding: 3px 15px;
  position: relative;
  padding-left: 0px;
}

.megamenu-child .megamenu-title::before {
  background-color: #fff;
  bottom: 0;
  content: "";
  height: 1px;
  left: 15px;
  opacity: 0.25;
  position: absolute;
  width: 30px;
}

.megamenu-child li a {
  left: 0;
  padding: 3px 0px;
  position: relative;
}

.megamenu-child li a:hover {
  color: #fff;
  opacity: 1;
}

.submenu-bg-image {
  position: relative;
}

.submenu-bg-image::before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.submenu-bg-image.dark::before {
  background-color: rgba(0, 0, 0, 0.9);
}

.submenu-bg-image.light::before {
  background-color: rgba(255, 255, 255, 0.9);
}

.submenu-bg-image.light li a {
  color: #333;
  opacity: 0.6;
}

.submenu-bg-image.light li a:hover {
  opacity: 1;
}

.submenu-bg-image.light .megamenu-title::before {
  background-color: #000;
}

/*----------------- Menu Hover ---------------*/

.nav-hover-1 {
  /*
  .sub-menu {
      li {
          a {
              font-size: 13px;
          }
          &:hover > {
              a {
                  background-color: $primary-color;
                  color: $light;
              }
          }
      }
  }*/
}

.nav-hover-1>li>a {
  font-size: 12px;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.5px;
}

.nav-hover-1>li:hover>a, .nav-hover-1>li:focus>a {
  background-color: #fff;
  color: #000;
}

.nav-hover-2 {
  /*
  .sub-menu {
      top: -webkit-calc(100% + 3px);
      top: calc(100% + 3px);
      li {
          a {
              font-size: 11px;
          }
          &:hover > {
              a {
                  background-color: $primary-color;
                  color: $light;
              }
          }
      }
  }*/
}

.nav-hover-2>li>a {
  font-size: 12px;
}

.nav-hover-2>li>a::before, .nav-hover-2>li>a::after {
  height: 3px;
  position: absolute;
  left: 0;
  width: 0;
  content: "";
  z-index: 1;
  -webkit-transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  -moz-transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  -o-transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.nav-hover-2>li>a::before {
  top: -3px;
}

.nav-hover-2>li>a::after {
  bottom: -3px;
  left: auto;
  right: 0;
}

.nav-hover-2>li:hover>a, .nav-hover-2>li:focus>a {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.nav-hover-2>li:hover>a::before, .nav-hover-2>li:hover>a::after, .nav-hover-2>li:focus>a::before, .nav-hover-2>li:focus>a::after {
  background-color: #0D52FF;
  width: 100%;
}

.nav-hover-3>li>a {
  position: relative;
}

.nav-hover-3>li>a::after {
  background-color: #0D52FF;
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 3px;
  -webkit-transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -moz-transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  -o-transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.nav-hover-3>li:hover a::after, .nav-hover-3>li.active a::after {
  width: 100%;
}

.nav-hover-3>li .mouseout::after {
  left: auto;
  right: 0;
}

.nav-hover-4>li>a {
  font-size: 12px;
}

.nav-hover-4>li>a::after {
  background-color: transparent;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: -1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.nav-hover-4>li:hover>a, .nav-hover-4>li.active>a {
  color: #fff;
}

.nav-hover-4>li:hover>a::after, .nav-hover-4>li.active>a::after {
  background-color: #0D52FF;
}

.secondLevelImgRings {
  display: none;
}

.secondLevelImgRings .Her {
  display: none;
}

.secondLevelImgEveryday {
  display: none;
}

.secondLevelImgLoose {
  display: none;
}

.secondLevelImgDiamond {
  display: none;
}

.secondLevelImgDesign {
  display: none;
}

.secondLevelImgNature {
  width: 70px;
  height: 70px;
  text-align: center;
  float: left;
}

.secondLevelImgLove {
  width: 70px;
  height: 70px;
  text-align: center;
  float: left;
}

.secondLevelImgThree {
  width: 70px;
  height: 70px;
  text-align: center;
  float: left;
}

.secondLevelImgAsymmetry {
  width: 70px;
  height: 70px;
  text-align: center;
  float: left;
}

.secondLevel43947 {
  width: 24% !important;
  float: left;
  text-align: center;
  position: absolute !important;
  bottom: 44px;
  left: 0px;
  border-right: 0px solid #cdcdcd;
  padding-left: 0px;
  margin-left: 10px;
}

.secondLevel43947 a {
  text-align: left;
  line-height: 70px !important;
}

.secondLevel43948 a {
  text-align: left;
  line-height: 70px !important;
}

.secondLevel43949 a {
  text-align: left;
  line-height: 70px !important;
}

.secondLevel43950 a {
  text-align: left;
  line-height: 70px !important;
}

.secondLevel43948 {
  width: 24% !important;
  float: left;
  text-align: center;
  margin-top: 25px;
  /* border-bottom: 1px solid #cdcdcd; */
  border-right: 0px solid #cdcdcd;
  padding-left: 0px;
  margin-left: 0px;
}

.secondLevel43949 {
  width: 24% !important;
  float: left;
  text-align: center;
  position: absolute !important;
  bottom: 124px;
  left: 0px;
  padding-left: 0px;
  margin-left: 10px;
}

.secondLevel43950 {
  width: 24% !important;
  float: left;
  text-align: center;
  position: absolute !important;
  bottom: 124px;
  left: 24%;
  padding-left: 0px;
  margin-left: 10px;
}

.secondLevel43964 {
  width: 24% !important;
  float: left;
  text-align: center;
  margin-top: 25px;
  padding-left: 0px;
  margin-left: 10px;
}

.secondLevel43964 a {
  text-align: left;
  line-height: 70px !important;
}

.secondLevelImgEnchanted {
  width: 70px;
  height: 70px;
  text-align: center;
  float: left;
}

.basketProduct {
  padding: 10px;
  border-top: 1px solid #f2e1c5;
}

.prod-info {
  vertical-align: middle;
}

.actions {
  margin: 25px 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
}

.prod-info, .prod-info span {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 100%;
  float: left;
}

.direction {
  float: left !important;
}

#ac-basket {
  display: flex !important;
  width: 300px !important;
}

.newsocial {
  margin-right: 10px;
  float: left;
  margin-top: 10px;
}

.facets-block {
  border-top: 1px solid #e2e1e1 !important;
  border-right: 1px solid #e2e1e1 !important;
}

.dataCount {
  display: initial;
  /* font-size: 14px; */
  font-size: 19px;
  font-family: 'Montserrat', sans-serif;
  color: #868686;
  font-weight: normal;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-device-width: 768px) and (min-device-width: 320px) {
  .mobile_header {
    background: #fff!important;
    height: 55px !important;
    line-height: 55px !important;
    position: fixed;
    z-index: 99;
    width: 100%;
  }
  .hoverimg:hover {
    box-shadow: none;
  }
  .chooseDiamond {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    height: 60px;
    width: 300px;
    font-size: 16px !important;
  }
  .tcenter {
    text-align: center !important;
  }
  .homeBg {
    padding-top: 30px;
    padding-bottom: 30px;
    background-repeat: initial !important;
    /* width: 100%; */
    background-clip: border-box;
  }
  
  .footer_logo1 img {
    width: auto;
  }
  .searchItems {
    color: #000;
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
  }
  .MobileSearchFiels {
    margin-bottom: 30px !important;
    border: 1px solid #545353 !important;
    border-radius: 4px !important;
    height: 34px!important;
    margin-top: 10px !important;
    padding-left: 10px !important;
    color: #000 !important;
    font-family: 'Montserrat', sans-serif;
  }
 
  .tRight {
    text-align: right !important;
  }
  .mobileSearch {
    display: none;
  }
  .mobileCon {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #Rings {
    display: none;
  }
  #Collections {
    display: none;
  }
  #Diamonds {
    display: none;
  }
  .color-Black {
    color: #000 !important;
    position: relative;
    top: 14px;
  }
  .icon-menu {
    margin-top: 1px;
    font-size: 24px!important;
    margin-right: 0;
    margin-left: 0;
    text-align: right;
    height: 50px !important;
    line-height: 50px !important;
  }
  .pt8 {
    margin: 0px;
    line-height: 48px;
  }
  .pbb {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 25px;
    float: left;
    padding-top: 0px;
  }
  .pl0 {
    padding-left: 0px !important;
    font-size: 18px!important;
  }
  .catDataImg {
    width: 30px;
    float: left;
    padding-top: 0px;
    margin-right: 10px;
  }
  .page-breadcrumb {
    margin-top: 20px;
  }
  .mainPanel {
    margin-top: 20px;
  }
  .page-title {
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .facets-block {
    border-top: 0px solid #dbdbdb;
    border-right: 0px solid #dbdbdb;
  }
  .mobileFacets {
    display: block !important;
    position: absolute;
    right: 30px;
    top: 136px;
  }
  .facetsContent {
    display: none !important;
  }
  .Mobilefacets-block {
    display: block !important;
  }
  .mt50 h4 {
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
  }
  .priceInfo h4 {
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;
  }
  .facetsHead {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px !important;
    margin-right: 50px !important;
    color: #fff !important;
  }
  .facetSave {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px !important;
    position: absolute !important;
    right: 5% !important;
  }
  .jss241 {
    color: #fff !important;
    background-color: #162345 !important;
  }
  .productCount {
    margin-right: 0% !important;
    font-size: 17px !important;
  }
  .facetData {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px !important;
  }
  .facetsButton {
    background: #fff !important;
    border: 1px solid #e2e1e1 !important;
    color: #333333!important;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize !important;
    font-size: 16px !important;
    /* border-radius: 32px !important; */
    cursor: pointer !important;
  }
  .mobileNotDisplay {
    display: none !important;
  }
  .mobileDisplay {
    display: block !important;
  }
  .brown-bg {
    background: none !important;
  }
  .mobileContentDisplay {
    position: fixed;
    z-index: 999;
    right: 10px !important;
    top: 10px;
  }
  .MobileLogo {
    width: auto;
    height: 32px;
    margin-top: 9px;
    margin-left: 10px;
    display: block !important;
    float: left;
  }
  .container {
    width: 90% !important;
  }
  .tyo {
    margin: 0 !important;
    width: 100% !important;
  }
  .socialMedia ul li {
    margin: 0px 0px 0px 7px !important;
  }
  .search-home {
    display: none !important;
  }
  .react-tooltip-lite {
    display: none !important;
  }
  .item-price {
    color: #dfb258 !important;
    font-size: 21px !important;
  }
  .numberOfItem {
    margin-top: 30px !important;
    text-align: center !important;
  }
  .paginationData {
    text-align: center !important;
    margin-top: 30px !important;
  }
  .buttonPageActive {
    padding: 4px 10px 6px !important;
    border-radius: 50% !important;
  }
  .buttonPage {
    padding: 10px 12px !important;
  }
  .y {
    margin-left: 0px !important;
    text-align: center;
    padding: 5px 10px !important;
    border-radius: 32px;
    display: inline-flex;
    top: 5px;
    position: relative;
  }
  .x {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .buttonPageLeft, .x {
    text-align: center;
    padding: 5px 10px !important;
    border-radius: 32px;
    display: inline-flex;
    top: 5px;
    position: relative;
  }
  .subsubCat {
    padding-left: 20px !important;
    font-size: 16px !important;
  }
  .subcaticon {
    position: relative;
    left: 7px !important;
  }
  .subCat {
    font-size: 17px !important;
  }
}

.mobileNotDisplay1 {
  display: none;
}

.MobileLogo {
  display: none;
}

.page-title {
  margin-bottom: 0px;
}

.mobileFacets {
  display: none;
}

.facetsContent {
  display: block;
}

.Mobilefacets-block {
  display: none;
}

.mobileNotDisplay {
  display: block;
}

.mobileDisplay {
  display: none;
}

.scrollup {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 100px;
  right: 15px;
  text-indent: -9999px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
}

/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
  */

@media only screen and (max-width: 760px), (min-device-width: 900px) and (max-device-width: 1023px) {
  .designaring {}
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  #diamond table, thead, tbody, th, td, tr {
    display: block;
  }
  .footer_logo1 img {
    width: auto;
  }
  .homeBgopacity {
    height: auto !important;
  }
  .text-link {
    font-size: 15px;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  #diamond thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #diamond tr {
    margin: 0 0 1rem 0;
  }
  #diamond tr:nth-child(odd) {
    background: #f9f9f9;
  }
  #diamond td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    height: 50px;
    display: block !important;
  }
  #diamond td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 15px;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  #diamond td:nth-of-type(1):before {
    content: "Image";
  }
  #diamond td:nth-of-type(2):before {
    content: "Shape";
  }
  #diamond td:nth-of-type(3):before {
    content: "Carats";
  }
  #diamond td:nth-of-type(4):before {
    content: "Colour";
  }
  #diamond td:nth-of-type(5):before {
    content: "Clarity";
  }
  #diamond td:nth-of-type(6):before {
    content: "Cut";
  }
  #diamond td:nth-of-type(7):before {
    content: "Certificate No";
  }
  #diamond td:nth-of-type(8):before {
    content: "Measurements";
  }
  #diamond td:nth-of-type(9):before {
    content: "Code";
  }
  #diamond td:nth-of-type(10):before {
    content: "Price";
  }
  #diamond td:nth-of-type(11):before {
    content: "Action";
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .socialMedia ul li {
    margin: 0 10px 0 12px!important;
  }
  .torder {
    text-align: right !important;
  }
  .scrollup {
    display: none !important;
  }
}

.LD_Table {
  display: none;
}

.LD_Depth {
  display: none;
}

.LD_Carat {
  margin: 0px 0px 20px;
  float: left;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 45px;
}

@media only screen and (min-device-width: 1000px) and (max-device-width: 1024px) {
  .looseDiamndList {
    margin: 0 7px 0 7px !important;
  }
  .dividers {
    display: none !important;
  }
  .logoSec a {
    padding: 0px !important;
  }
  .footer_logo1 img {
    width: 100%;
  }
}

@media only screen and (min-device-width:1024px) and (max-device-width:1360px) {
  #Rings, #Collections, #Diamonds {
    background-size: 280px 280px !important;
  }
  .secondLevel43947 {
    width: 33% !important;
  }
  .secondLevel43498 {
    width: 40% !important;
    float: left !important;
    margin-left: 2% !important;
  }
  .secondLevel43949 {
    width: 33% !important;
  }
  .secondLevel41206 {
    width: 33% !important;
    float: left !important;
    margin-left: 2%!important;
  }
  .megamenu-child {
    min-width: 320px;
  }
  .secondLevel43950 {
    width: 33% !important;
    left: 32%;
  }
  .home-img {
    width: 60% !important;
  }
  .p36 {
    padding-left: 0px !important;
  }
}

.DyoPrice {
  width: 33%;
  float: left;
}

.facetHeading {
  margin: 20px 0px;
  font-size: 18px;
  width: 100%;
  text-align: left;
}

.active-Facet {
  border: 2px solid #dba842;
  float: left;
  border-radius: 4px;
  text-align: center;
  margin: 2px;
  padding: 0px 10px;
}

.inactive-Facet {
  border: 2px solid #fff;
  float: left;
  border-radius: 4px;
  text-align: center;
  margin: 2px;
  padding: 0px 10px;
}

.dyofacetImg {
  float: left;
  margin-right: 0px;
}

.dyoFacet-Princess {
  width: 50px;
}

.dyoFacet-Heart {
  width: 50px;
}

.dyoFacet-Round {
  width: 50px;
}

.DyoborderTop {
  border-top: 0px solid #e2e1e1;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 10px !important;
}

.Dyoreset {
  float: right;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  margin: 15px 0px;
  color: #d69c28;
  width: 100%;
}

.Dyoreset span {
  cursor: pointer;
  float: right;
}

.Dyoreset i {
  float: left;
  margin-right: 5px;
}

.DyoRings {
  margin-top: 20px;
}

.plr0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

table {
  width: 99% !important;
  display: table;
  margin: 0 auto;
}

.rc-slider-mark span:first-child {
  left: 32.966% !important
}

.rc-slider-mark span:last-child {
  left: 66.966% !important
}

.rc-slider-step span:first-child {
  left: 32.966% !important
}

.rc-slider-step span:last-child {
  left: 66.966% !important
}

.PhoneDetails {
  float: left;
}

.PhoneDetails img {
  float: left;
  padding-top: 2px;
}

.PhoneDetails p {
  float: left;
  color: #fff;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
  margin-left: 10px;
}

.watsApp {
  top: 0px !important;
  background: #b7800c;
  margin-right: 40px !important;
}

.watsApp a {
  color: #fff;
  padding: 0px 10px !important;
  height: 30px;
  line-height: 28px;
  text-transform: capitalize;
  margin-left: 10px;
  font-size: 12px;
}

.watsApp a:hover {
  color: #fff !important;
}

.watsApp a:active {
  color: #fff !important;
}

.phoneData {
  margin-right: 40px !important;
}

.title-ls .price {
  font-family: 'Montserrat', sans-serif;
  font-size: 19px;
}

.videoSection {
  text-align: center;
  margin: 30px 0px;
}

.videoSection h1 {
  font-size: 24px;
  color: #b7800c;
  margin: 15px 0px;
}

.videoSection p {
  font-family: 'Montserrat', sans-serif;
  width: 585px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 16px;
}

.videoSection img {
  width: 550px;
}

.bggg {
  background: #c6a766 !important;
  color: #fff !important;
  border: 1px solid #c6a766 !important;
  padding: 7px 20px !important;
  border-radius: 16px !important;
  margin-right: 20px;
}

.bggg:hover {
  background: #c6a766 !important;
  color: #fff !important;
}

.bggg:active {
  background: #c6a766 !important;
  color: #fff !important;
}

.bggg:focus {
  background: #c6a766 !important;
  color: #fff !important;
}

.bggg1 {
  background: #56af17 !important;
  color: #fff !important;
  border: 1px solid #56af17 !important;
  padding: 7px 20px !important;
  border-radius: 16px !important;
  margin-right: 20px;
}

.bggg1:hover {
  background: #56af17 !important;
  color: #fff !important;
}

.bggg1:active {
  background: #56af17 !important;
  color: #fff !important;
}

.bggg1:focus {
  background: #56af17 !important;
  color: #fff !important;
}

.bggg2 {
  background: #fff !important;
  color: #000 !important;
  border: 1px solid #fff !important;
  padding: 7px 20px !important;
  border-radius: 16px !important;
}

.bggg2:hover {
  background: #fff !important;
  color: #000 !important;
}

.bggg2:active {
  background: #fff !important;
  color: #000 !important;
}

.bggg2:focus {
  background: #fff !important;
  color: #000 !important;
}

.loose ul {
  width: 800px;
  margin: 0 auto;
}

.bggg2_mobile {
  display: none;
}

.bggg2_mobile:hover {
  background: #fff !important;
  color: #000 !important;
}

.bggg2_mobile:active {
  background: #fff !important;
  color: #000 !important;
}

.bggg2_mobile:focus {
  background: #fff !important;
  color: #000 !important;
}

.secondLevel44219 img {
  display: block !important;
}

.secondLevel44220 img {
  display: block !important;
}

.secondLevel44222 img {
  display: block !important;
}

.secondLevel44217 {
  float: left;
  width: auto !important;
  margin-left: 180px;
}

.secondLevel44224 {
  float: left;
  width: auto !important;
  margin-left: -45px;
}

.secondLevel44225 {
  float: left;
  width: auto !important;
  margin-left: -45px;
}

.secondLevel44217 p {
  position: absolute;
  left: -190px;
  top: 0px;
  display: none;
}

.secondLevel44224 p {
  position: absolute;
  left: -450px;
  top: 20px;
  display: none;
}

.secondLevel44225 p {
  position: absolute;
  left: -710px;
  top: 55px;
  display: none;
}

.secondLevel44217 img {
  width: 90%;
  margin-top: 10px;
}

.secondLevel44224 img {
  width: 90%;
  margin-top: 10px;
}

.secondLevel44225 img {
  width: 90%;
  margin-top: 10px;
}

.Gifting {
  pointer-events: none;
  cursor: default;
}

.covid19 {
  background-color: #f5e8d0;
  color: #000000;
  line-height: initial;
  margin-bottom: 0px !important;
  width: 100%;
}

.covid19 p {
  margin-bottom: 0px;
  line-height: 24px;
  font-size: 16px;
  float: left;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
}

.item .twoButtonBanner a.button {
  padding-left: 0;
  padding-right: 0;
  min-width: 230px;
}

a.button.whiteBorder, input.button.whiteBorder, button.button.whiteBorder, span.button.whiteBorder {
  border: 1px solid #fff;
  color: #fff;
  padding-bottom: 14px;
  padding-top: 14px;
  position: absolute;
  top: 270px;
  right: 270px;
  z-index: 99999;
}

a.button, input.button, button.button, span.button {
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  letter-spacing: .15em;
  line-height: 24px;
  min-width: 150px;
  padding: 12px 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.item .twoButtonBanner a, .button {
  transition: background-color .3s, border-color .3s, color .3s;
}

a.button.whiteBorder:hover, input.button.whiteBorder:hover, button.button.whiteBorder:hover, span.button.whiteBorder:hover {
  background: #fff;
  color: #252525;
}

a.button1.whiteBorder1, input.button1.whiteBorder1, button.button1.whiteBorder1, span.button1.whiteBorder1 {
  border: 1px solid #fff;
  color: #fff;
  padding-bottom: 14px;
  padding-top: 14px;
  position: absolute;
  top: 220px;
  right: 260px;
  z-index: 99999;
}

a.button1, input.button1, button.button1, span.button1 {
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  letter-spacing: .15em;
  line-height: 24px;
  min-width: 150px;
  padding: 15px 40px;
  text-align: center;
  text-transform: uppercase;
}

.item .twoButtonBanner a, .button {
  transition: background-color .3s, border-color .3s, color .3s;
}

a.button1.whiteBorder1:hover, input.button1.whiteBorder1:hover, button.button1.whiteBorder1:hover, span.button1.whiteBorder1:hover {
  background: #fff;
  color: #252525;
}

.divi {
  height: 2px;
  width: 230px;
  background: #ffb823;
  text-align: center;
  margin: 10px auto 50px auto;
}

.populardivi {
  height: 2px;
  width: 230px;
  background: #ffb823;
  text-align: center;
  margin: 10px auto 10px auto;
}

.ourCollections {
  font-size: 25px;
  color: #393838;
  margin: 40px 0px 0px 0px;
}

.homeBody {
  width: 100%;
  border-radius: 16px;
}

.viewallcollections {
  border: 2px solid #bbaee3;
  padding: 12px 35px;
  /* float: left; */
  /* margin: 50px auto 0 auto; */
  position: relative;
  top: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  border-radius: 2px;
}

.mt68 {
  margin-top: 68px;
}

.ourProcess {
  font-size: 25px;
  color: #393838;
  margin: 40px 0px 0px 0px;
  text-align: center;
}

.processdivi {
  height: 2px;
  width: 230px;
  background: #ffb823;
  text-align: center;
  margin: 10px auto 90px auto;
}

.processDelivery {
  width: 20%;
  margin-left: auto;
  left: auto;
  right: auto;
  float: left;
  box-sizing: border-box;
  padding: 0 .75rem;
  min-height: 1px;
}

.processDelivery p {
  font-family: 'Montserrat', sans-serif;
  margin: 20px 0px;
  font-size: 16px;
  color: #393838;
  text-transform: uppercase;
}

.processEnquire {
  background: #ffc958;
  padding: 10px 30px;
  border: none;
  border-radius: 45px;
  width: 20%;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  position: absolute;
  margin-top: 120px;
  left: 40%;
  text-align: center;
}

.legacy {
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 1200px;
}

.legacyBg {
  background-color: hsla(0, 23%, 91%, 0.9);
  margin-top: 30%;
  width: 31.5%;
  /* margin: 0px 15px; */
  margin-right: 2.5%;
  border-radius: 4px;
  float: left;
  box-sizing: border-box;
  padding: 1.5rem;
  min-height: 1px;
  margin-left: auto;
  left: auto;
  right: auto;
  padding-bottom: 3rem;;
  font-family: 'Montserrat', sans-serif;
  line-height : 22px;
  font-size : 16px;
}

.ourlegacy {
  font-size: 20px;
  margin: 50px 0px 10px 0px;
  font-family : 'Merriweather', serif !important;
}

.ourl {
  height: 2px;
  width: 230px;
  background: #ffb823;
  text-align: center;
  margin: 10px auto 35px auto;
}

.legacyBg p {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  /* text-align: justify; */
  /* width: 90%;
  margin-left: 5%; */
  line-height: 22px;
}

.legacyBg1 {
  margin-top: 2.5%;
  margin-bottom: 5%;
  width: 31.5%;
  /* margin: 0px 15px; */
  margin-right: 2.5%;
  border-radius: 4px;
  padding: 0px !important;
  float: left;
  box-sizing: border-box;
  min-height: 1px;
  margin-left: auto;
  left: auto;
  right: auto;
}

.nav-btn {
  height: 47px;
  width: 26px;
  cursor: pointer;
  top: 100px !important;
}

.arrow-bg-rt {

  right: -33px;
}
.arrow-bg-rt:hover {
  background-position: -24px -53px;
}

.arrow-bg-lt {
 
  left: -33px;
}
.arrow-bg-lt:hover {
  background-position: 0px -53px;
}
.processDelivery img {
  width: 90px;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  .legacyBg {
    width: 100%;
    margin-top: 6%;
  }
  .socialMedia ul li {
    top: 7px !important;
  }
  .pointy {
    position: relative;
    background-color: #f1f1f1;
    display: inline-block;
    font-size: 14px;
    color: #333;
  }
  .pointy:after {
    position: absolute;
    content: "";
    height: 0;
    width: 0;
    font-size: 0;
    line-height: 0;
    border-top: 27px solid transparent;
    border-bottom: 23px solid transparent;
    border-left: 20px solid #f1f1f1;
    right: -20px;
  }
  .pointy:before {
    position: absolute;
    content: "";
    left: -20px;
    height: 0;
    width: 0;
    font-size: 0;
    line-height: 0;
    border-top: 26px solid #f1f1f1;
    border-bottom: 25px solid #f1f1f1;
    border-right: 15px solid #f1f1f1;
    border-left: 15px solid transparent;
  }
  .legacyBg1 {
    width: 100%;
  }
  .processDelivery {
    width: 100%;
  }
  .processEnquire {
    left: 25%;
    width: 50%;
  }
  .mobileInfo {
    margin-top: 10px !important;
    padding: 0px !important;
  }
  .viewallcollections {
    display: none;
  }
  .divi {
    margin: 10px auto 20px auto !important;
  }
  .ourProcess {
    margin: 30px 0px 0px 0px !important;
  }
  .processdivi {
    margin: 10px auto 30px auto !important;
  }
  .legacy {
    padding: 30px 0px !important;
  }
  .torder {
    height: 50px;
    line-height: 12px;
  }
  .owl-nav {
    display: none !important;
  }
  .mostpopular .owl-nav {
    display: block !important;
  }
  .testimonialsInfo {
    padding-bottom: 40px !important;
  }
  .testimonialsData{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 22px;
  }
  .testimonial-title{
    font-family: 'Montserrat', sans-serif;
  }
  .testimonialsData p {
    width: 100% !important;
    margin-left: 0% !important;
    margin-bottom: 4% !important;
  }
  .legacy {
    
    background-repeat: no-repeat !important;
    background-attachment: scroll !important;
    min-height: 830px !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }
  .mobileYes {
    display: block !important;
    margin-top: 6% !important;
    margin-bottom: 0% !important;
    width: 100%;
    float: left;
  }
  .mobileNone {
    display: none !important;
  }
  .processIcon::after {
    content: '\00a0' !important;
    width: 1px !important;
    height: 60px !important;
    position: relative !important;
    top: 0px !important;
    left: 50% !important;
    width: 1px !important;
    height: 60px !important;
    float: left;
    display: none;
  }
  .processIcon1::after {
    content: '\00a0' !important;
    width: 1px !important;
    height: 60px !important;
    position: relative !important;
    top: 0px !important;
    left: 50% !important;
    width: 1px !important;
    height: 60px !important;
    float: left;
    display: none;
  }
  .processDelivery img {
    width: 95px;
  }
  .m-padd0 {
    padding: 0 !important;
  }
  .m-container {
    width: 100% !important;
  }
  .viewmore-text {
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .viewmore-text.material-icons {
    font-size: 30px;
  }
  .process-block .owl-nav {
    display: block !important;
  }
  .process-block .noArrow .owl-nav {
    display: none !important;
  }
  .process-block .owl-nav .owl-next {
    position: absolute;
    right: 0px;
    top: 35vw !important;
  }
  .process-block .owl-nav .owl-prev {
    position: absolute;
    left: 0px;
    top: 35vw !important;
  }
  .viewmore-block {
    height: 80vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.DesktopNo {
  display: none;
}

.desktopOk {
  display: block;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
  .mobileInfo {
    margin-top: 10px !important;
    padding: 0px !important;
  }
  .viewallcollections {
    display: none;
  }
  .processIcon::after {
    content: '\00a0' !important;
    width: 1px !important;
    height: 60px !important;
    position: relative !important;
    top: 0px !important;
    left: 50% !important;
    width: 1px !important;
    height: 60px !important;
    float: left;
    display: none;
  }
  .processIcon1::after {
    content: '\00a0' !important;
    width: 1px !important;
    height: 60px !important;
    position: relative !important;
    top: 0px !important;
    left: 50% !important;
    width: 1px !important;
    height: 60px !important;
    float: left;
    display: none;
  }
  .processDelivery img {
    width: 95px;
  }
  .m-padd0 {
    padding: 0 !important;
  }
  .m-container {
    width: 100% !important;
  }
  .viewmore-block {
    height: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .viewmore-text {
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .viewmore-text.material-icons {
    font-size: 30px;
  }
  .process-block .owl-nav {
    display: block !important;
  }
  .process-block .owl-nav .owl-next {
    position: absolute;
    right: 0px;
    top: 38% !important;
  }
  .process-block .owl-nav .owl-prev {
    position: absolute;
    left: 0px;
    top: 38% !important;
  }
  .legacyBg {
    width: 100%;
    margin-top: 10%;
    padding-bottom: 30px;
  }
  .legacyBg1 {
    width: 100%;
  }
  .processDelivery {
    width: 100%;
  }
  .legacy {
    padding: 30px 0px !important;
  }
  .mobileYes {
    display: block !important;
    margin-top: 6% !important;
    margin-bottom: 0% !important;
    width: 100%;
    float: left;
  }
  .mobileNone {
    display: none !important;
  }
}

.mostpopular .owl-carousel .owl-nav.disabled {
  display: block;
}

.mostpopular .owl-prev {
  top: 190px !important;
}

.mostpopular .owl-next {
  top: 190px !important;
}

.opacity30 {
  opacity: .4;
}

.absBox {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.bgCover {
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonialsInfo {
  background-color: #f0f0f0;
  padding: 40px 0px;
  margin: 0px 0px;
  min-height: 300px;
  height: auto;
}

.readState {
  font-style: italic;
  color: #c6a766;
  cursor: pointer;
}

.reviewImg {
  max-height: 100%;
  margin: auto;
  border-radius: 10px;
}
.testimonial-img-wrapper-mobile{
  display: flex;
  height : 200px;
}

.testimonialsParent {
  margin: 1% 0%;
}
.testimonialsData{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 22px;
}
.testimonial-title{
  font-family: 'Montserrat', sans-serif;
}
.testimonialsData h3 {
  font-size: 25px;
  margin-bottom: 1.2%;
  text-transform: capitalize;
}

.testimonialsData p {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 1%;
  height: auto;
  height: fit-content;
}

.processIcon::after {
  content: "-------";
  position: relative;
  top: -110px;
  right: -123px;
}

.processIcon1::after {
  content: "-------";
  position: relative;
  top: -130px;
  right: -123px;
}

.menu-icon {
  float: left;
}

.menu-wrapper {
  display: inline-block;
}

.menuDataaa {
  position: fixed;
  background: #ff0000;
  width: 100%;
  z-index: 99999;
  top: 55px;
  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 1s;
  max-height: calc(100vh - 55px);
  overflow: auto;
}
.menuBackdrop{
  position: fixed;
  background:rgba(0, 0, 0, 0.3);
  top:55px;
  left:0;
  right:0;
  bottom:0;
  z-index: 99;
}

.sidenav {
  width: 100% !important;
  /* position: absolute !important; */
  transition-property: width;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 1s;
  top: 0px;
}

.tcenter {
  text-align: center;
}

.p0 {
  padding-left: 0px !important;
}

@media only screen and (min-width:1024px) {
  .legacy-wrapper {
    display: flex;
  }
  .owl-next {
    top: 190px !important;
  }
  .owl-prev {
    top: 190px !important;
  }
  .testimonial-wrapper {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }
  .testimonial-img-wrapper {
    width: 400px;
    height: 400px;
    position: relative;
  }
  .testimonial-img-wrapper .reviewImg {
    width: auto !important;
    height: auto !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
  }
  .testimonial-block-1 {
    flex: 2;
    display: flex;
    align-items: center;
  }
  .testimonial-block-2 {
    flex: 3;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    line-height: 22px;
  }
  .testimonial-title{
    font-family: 'Montserrat', sans-serif;
  }
}